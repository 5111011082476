<template>
  <div>
    <div class="main-title">消息列表</div>
    <div class="content-list-page page-content">
      <div class="list-head-box">
        <div data-v-69874ad0="" class="list-head">
          <p data-v-69874ad0="" class="list-head-title">温馨提示</p>
          <ul data-v-69874ad0=""></ul>
        </div>
      </div>

      <div class="padding-t-sm padding-b-sm">
        <span class="fl-r">
          <router-link to="/userMessage/add" class="router-link">
            <el-button type="primary" size="small">发布消息</el-button>
          </router-link>
        </span>

        <div style="clear: both"></div>
      </div>

      <div class="table-content">
        <el-table
          ref="singleTable"
          :data="tableData"
          v-loading="loading"
          style="width: 100%; background: #fff"
        >
          <el-table-column label="ID" property="id" width="80">
          </el-table-column>

          <el-table-column label="标题" property="title"> </el-table-column>

          <el-table-column label="发布者" property="creator"> </el-table-column>

          <el-table-column label="通知对象" property="creator">
            <template slot-scope="scope"
              ><span>{{ scope.row.is_all == 1 ? "全部" : scope.row.aims }}</span></template
            >
          </el-table-column>

        

          <el-table-column property="created_at" label="创建时间">
          </el-table-column>

          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-link type="primary" @click="particulars(scope.row)"
                >详情</el-link
              >-
              <router-link
                :to="'/userMessage/detail/' + scope.row.id"
                class="router-link"
              >
                <el-link type="primary">编辑</el-link></router-link
              >-
              <!-- <el-link type="primary" @click="deleteMessage(scope.row)"
                >详情</el-link
              > -->

              <el-popconfirm
                title="确定删除此消息吗"
                @confirm="deleteMessage(scope.row)"
              >
                <el-button slot="reference" type="text">删除 </el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="table-batch">
        <span class="fl-r">
          <el-pagination
            small
            background
            :current-page="page"
            :page-sizes="[10, 20]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="setPageSize"
            @current-change="setPage"
            :total="total"
          >
          </el-pagination>
        </span>
        <div style="clear: both"></div>
      </div>
    </div>

    <el-dialog
      title="详情"
      :visible.sync="dialogVisible"
      append-to-body
      width="50%"
    >
      <div class="information" v-loading="dialogVisibleLoading">
        <div>标题：{{ information.title }}</div>
        <div>微信通知内容：{{ information.brief }}</div>
        <div>
          通知对象：
          {{(information.is_all == 1?'全部':information.aims)}}
        </div>
       <div>
        <div>详情</div>
        <div v-html="information.detail"></div>
       </div>
        <div class="time_name">
          <div>{{ information.creator }}</div>
          <div>{{ information.created_at }}</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "list",
  data() {
    return {
      loading: false,
      tableData: [],
      page: 1,
      dialogVisible: false,
      dialogVisibleLoading: false,
      pageSize: 10,
      total: 0,
      information: {},
    };
  },
  methods: {
    ...mapActions("user", ["noticeList", "noticeDetail"]),
    ...mapActions("common", ["delete"]),
    async particulars(row) {
      this.dialogVisible = true;
      this.dialogVisibleLoading = true;
      const { data } = await this.noticeDetail({ id: row.id });
      this.information = data;
      this.dialogVisibleLoading = false;
    },
    async deleteMessage(e) {
      this.delete({ id: e.id, model: "Notice\\Notices" }).then((res) => {
        if (res.ret === 0) {
          this.$message.success("删除成功");
          this.getList();
        }
      });
    },
    async getList() {
      this.loading = true;
      let form = {
        page: this.page,
        pageSize: this.pageSize,
      };
      const { data } = await this.noticeList(form);
      this.tableData = data.list;
      this.total = data.total;
      this.loading = false;
    },
    setPageSize(pageSize) {
      this.pageSize = pageSize;
      this.getList();
    },
    setPage(page) {
      this.page = page;
      this.getList();
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style>
.el-popover {
  min-width: 80px;
}
.information {
  text-align: center;
  line-height: 26px;
}
.time_name {
  display: flex;
  justify-content: space-evenly;
}
</style>
